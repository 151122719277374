// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-change-password-js": () => import("../src/pages/change-password.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-create-profile-js": () => import("../src/pages/create-profile.js" /* webpackChunkName: "component---src-pages-create-profile-js" */),
  "component---src-pages-desktop-js": () => import("../src/pages/desktop.js" /* webpackChunkName: "component---src-pages-desktop-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-register-js": () => import("../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-remind-js": () => import("../src/pages/remind.js" /* webpackChunkName: "component---src-pages-remind-js" */)
}

