// Configure Firebase
const config = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID
};

class Firebase {
    constructor() {
        if (typeof window !== "undefined") {
            const firebase = require("firebase");

            firebase.initializeApp(config);
            this.auth = firebase.auth;
        }
    }
}

export default new Firebase();


