import React from "react";
import AuthProvider from './AuthProvider';

const Layout = ({ children }) => (
    <AuthProvider>
        {children}
    </AuthProvider>
);

export default Layout
