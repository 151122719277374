import FirebaseService from "services/firebase.js"
import React from 'react';
import {navigate} from "gatsby-link";
import axios from "axios";
import {isDesktop} from "services/utils";
import { navigateWithParam } from "../../services/utils"

class AuthProvider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firebase: FirebaseService,
            user: null,
            userFirebaseData: {},
            userProfile: {}
        };
    }

    componentDidMount = () => {
        if (isDesktop()) {
            navigate(`/desktop/`);
            return null;
        }

        const {firebase} = this.state;
        const firebasePackage = require("firebase");

        const database = firebasePackage.firestore();
        var self = this;

        firebase.auth().onAuthStateChanged((user) => {
            if (window.location.pathname === `/desktop/`) {
                return;
            }
            if (user) {
                this.setState({user: user});

                const document = database.collection("users").doc(user.uid);
                document.get().then(function (doc) {
                    if (doc.exists) {
                        let { profileId, accessToken } = doc.data();

                        let params = {
                            "access-token": accessToken,
                            "expand": "saldoHistory,questionnaires,address,contestToUser.contest,welcomepack,taxOffice"
                        }

                        axios
                            .get(`${process.env.API_URL}/profiles/${profileId}`, {params})
                            .then(profile => {
                                self.setState({
                                    userFirebaseData: doc.data(),
                                    userProfile: profile.data,
                                });
                            })
                            .catch(error => {
                                console.log("Error with API:", error);
                            });
                    } else {
                        // There is no document => Create profile
                        window.setTimeout(() => {
                            let firstUser = sessionStorage.getItem("firstUser")
                            if (firstUser === 'false') {
                                navigate(`/app/dashboard/`)
                            } else {
                                navigateWithParam("/create-profile/", firstUser)
                            }
                        }, 300)

                    }
                }).catch(function (error) {
                    console.log("Error getting document:", error);
                });
            } else {
                // Clear all user info
                firebase.auth().signOut().then(() => {
                    this.setState({
                        user: null,
                        userProfile: null,
                        userFirebaseData: null
                    });
                });

                // @fixme Problems with trailing `/` - ugly solution
                if (window.location.pathname !== `/login/` &&
                    window.location.pathname !== `/register/` &&
                    window.location.pathname !== `/remind/` &&
                    window.location.pathname !== `/change-password/` &&
                    window.location.pathname !== `/create-profile/`
                ) {
                    // Redirect to login page
                    navigate(`/login/`, {replace: true});
                }
            }
        });
    };

    updateProfile = () => {
        let params = {
            "access-token": this.state.userFirebaseData.accessToken,
            "expand": "saldoHistory,questionnaires,address,contestToUser.contest,welcomepack"
        }

        axios
            .get(`${process.env.API_URL}/profiles/${this.state.userFirebaseData.profileId}`, { params })
            .then(profile => {
                this.setState({
                    userProfile: profile.data,
                });
            })
            .catch(error => {
                console.log("Error with API:", error);
            });
    };

    setProfile = (profile) => {
        this.setState({
            userProfile: profile,
        });
    };

    render() {
        const childrenWithProps = React.Children.map(this.props.children, child =>
            React.cloneElement(child, {
                auth: this.state.firebase.auth,
                user: this.state.user,
                userFirebaseData: this.state.userFirebaseData,
                userProfile: this.state.userProfile,
                updateProfile: this.updateProfile,
                setProfile: this.setProfile,
            })
        );
        return childrenWithProps;
    }
}

export default AuthProvider;